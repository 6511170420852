import style from "./Riddle7.module.css";
// import { PatternFormat } from "react-number-format";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faComputer } from "@fortawesome/free-solid-svg-icons";

function Riddle7({ setValid, isDisplayed, setDisplayed, id }) {
  const riddlesData = require("./../../datas/riddles.json");

  let className = `riddlediv ${id}`;

  // function Validator(input, expected) {
  //   if (input === expected) {
  //     console.log("validé");
  //     setValid(true);
  //     setDisplayed("8");
  //   } else {
  //     console.log("pas validé");
  //     setValid(false);
  //     alert("Mauvaise réponse Mimi, réessaye ! Les codes peuvent être composés de chiffres ou de lettres !");
  //   }
  // }

  // function handleSubmit(e) {
  //   // Prevent the browser from reloading the page
  //   e.preventDefault();
  //   // Read the form data
  //   const form = e.target;
  //   const formData = new FormData(form);
  //   // You can work with it as a plain object:
  //   const formJson = Object.fromEntries(formData.entries());
  //   var riddle7_input = formJson.riddle7_input;
  //   Validator(riddle7_input, riddlesData.riddles[6].expected);
  // }

  //Class modifying in order to display the actual riddle
  if (isDisplayed === id) {
    className += " active";
  } else {
    className += " inactive";
  }

  document.addEventListener('play', function(e) {
    var audios = document.getElementsByTagName('audio');

    for (var i = 0, len = audios.length; i < len; i++) {
        if (audios[i] !== e.target) {
            audios[i].pause();
        }
    }
}, true);

  return (
    <div className={className}>
      <div className={style.generalriddle}>
        {/* <h1 className={style.title}>Escape Mimi</h1> */}

        {/* <FontAwesomeIcon icon={faComputer} className={style.computer_icon} /> */}
        <div className={style.cluediv}>
          <video controls autoplay id="hellovideo" className={style.clue}>
            <source type="video/mp4" src={riddlesData.riddles[6].media} />
          </video>
          <div className={style.musicdiv}>
            <div className={style.musicdivOne}>
              <p>La Kiffance</p>
            <audio controls>
              <source type="audio/mpeg" src={riddlesData.riddles[6].music1}/>
            </audio>
            </div>
            
            <div className={style.musicdivOne}>
              <p>Mi Mi MI</p>
            <audio controls>
              <source type="audio/mpeg" src={riddlesData.riddles[6].music2}/>
            </audio>
            </div>
            <div className={style.musicdivOne}>
              <p>Pour les reufs</p>
            <audio controls>
              <source type="audio/mpeg" src={riddlesData.riddles[6].music3}/>
            </audio>
            </div>
          </div>
        </div>

        {/* <div className={style.riddleformdiv}>
          <form
            onSubmit={handleSubmit}
            name="riddle7_form"
            className={style.riddleform_in}
          >
            <label for="riddle7_input">Entrer le Code</label>

            <PatternFormat
              format="####"
              allowEmptyFormatting
              mask="- "
              className={style.riddleinput}
              name="riddle7_input"
            />

            <input
              type="submit"
              value="Vérifier"
              id="submit"
              className={style.riddlesubmit}
            />
          </form>
        </div> */}
      </div>
    </div>
  );
}

export default Riddle7;
