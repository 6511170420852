import { Link } from "react-router-dom";

function Header() {
  function ClearProgression() {
    sessionStorage.clear();
  }
  return (
    <div className="header">
      <div className="nav">
        <Link to="/" className="navlink">
          Page Principale
        </Link>
        <Link to="/library" className="navlink">
          Bibliothèque des anciens indices
        </Link>
        <button
          onClick={() => {
            ClearProgression();
            window.location.reload();
          }}
          className="navreset">
          Recommencer depuis le début
        </button>
      </div>
    </div>
  );
}

export default Header;
