function LibraryCard({ media, mediatype }) {
  if (mediatype === "photo") {
    return (
      <div className="library_card">
        <img src={media} alt="clue" />
      </div>
    );
  } else if (mediatype === "video") {
    return (
      <div className="library_card">
        <video controls>
          <source type="video/mp4" src={media} />
        </video>
      </div>
    );
  }
}

export default LibraryCard;
