import style from "./Riddle5.module.css";
import { PatternFormat } from "react-number-format";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComputer } from "@fortawesome/free-solid-svg-icons";

function Riddle5({ setValid, isDisplayed, setDisplayed, id }) {
  const riddlesData = require("./../../datas/riddles.json");

  let className = `riddlediv ${id}`;

  function Validator(input, expected) {
    if (input === expected) {
      console.log("validé");
      setValid(true);
      setDisplayed("6");
    } else {
      console.log("pas validé");
      setValid(false);
      alert("Mauvaise réponse Mimi, réessaye ! Les codes peuvent être composés de chiffres ou de lettres !");
    }
  }

  function handleSubmit(e) {
    // Prevent the browser from reloading the page
    e.preventDefault();
    // Read the form data
    const form = e.target;
    const formData = new FormData(form);
    // You can work with it as a plain object:
    const formJson = Object.fromEntries(formData.entries());
    var riddle5_input = formJson.riddle5_input;
    Validator(riddle5_input, riddlesData.riddles[4].expected);
  }

  //Class modifying in order to display the actual riddle
  if (isDisplayed === id) {
    className += " active";
  } else {
    className += " inactive";
  }

  return (
    <div className={className}>
      <div className={style.generalriddle}>
        {/* <h1 className={style.title}>Escape Mimi</h1> */}

        
        <div className={style.cluediv}>
          <video controls autoplay id="hellovideo" className={style.clue}>
            <source type="video/mp4" src={riddlesData.riddles[4].media} />
          </video>
        </div>

        <div className={style.riddleformdiv}>
          <form
            onSubmit={handleSubmit}
            name="riddle5_form"
            className={style.riddleform_in}
          >
            <label for="riddle5_input">Entrer le Code</label>

            <PatternFormat
              format="####"
              allowEmptyFormatting
              mask="- "
              className={style.riddleinput}
              name="riddle5_input"
            />

            <input
              type="submit"
              value="Vérifier"
              id="submit"
              className={style.riddlesubmit}
            />
          </form>
          <FontAwesomeIcon icon={faComputer} className={style.computer_icon} />
        </div>
      </div>
    </div>
  );
}

export default Riddle5;
