import Riddle1 from "./../../components/Riddle1";
import Riddle2 from "./../../components/Riddle2";
import Riddle3 from "./../../components/Riddle3";
import Riddle4 from "./../../components/Riddle4";
import Riddle5 from "./../../components/Riddle5";
import Riddle6 from "./../../components/Riddle6";
import Riddle7 from "./../../components/Riddle7";
import { useEffect, useState } from "react";

function Home({ isDisplayed, setDisplayed }) {
  const generalData = require("./../../datas/general.json");
  const [isValid, setValid] = useState();

  //Handle autoplay/////
  function handlePlay() {
    document.getElementById("glitchvideo").play();
  }

  function handleStop() {
    if (isDisplayed !== "0") {
      document.getElementById("glitchdiv").style.display = "none";
    } else {
      document.getElementById("glitchdiv").addEventListener("click", () => {
        document.getElementById("glitchdiv").style.display = "none";
        setDisplayed("1");
      });
    }
  }

  useEffect(() => {
    handlePlay();
    handleStop();
  }, []);
  //////////////////

  return (
    <div className="general">
      <div className="intro">
        <div className="glitch" id="glitchdiv">
          <video autoPlay muted loop id="glitchvideo">
            <source type="video/mp4" src={generalData.intro.glitch} />
          </video>
          <audio src={generalData.intro.glitchsound} id="glitchsound" />
        </div>
      </div>
      <Riddle1
        setValid={setValid}
        isDisplayed={isDisplayed}
        setDisplayed={setDisplayed}
        id="1"
      />
      <Riddle2
        setValid={setValid}
        isDisplayed={isDisplayed}
        setDisplayed={setDisplayed}
        id="2"
      />
      <Riddle3
        setValid={setValid}
        isDisplayed={isDisplayed}
        setDisplayed={setDisplayed}
        id="3"
      />
      <Riddle4
        setValid={setValid}
        isDisplayed={isDisplayed}
        setDisplayed={setDisplayed}
        id="4"
      />
      <Riddle5
        setValid={setValid}
        isDisplayed={isDisplayed}
        setDisplayed={setDisplayed}
        id="5"
      />
      <Riddle6
        setValid={setValid}
        isDisplayed={isDisplayed}
        setDisplayed={setDisplayed}
        id="6"
      />
      <Riddle7
        setValid={setValid}
        isDisplayed={isDisplayed}
        setDisplayed={setDisplayed}
        id="7"
      />
    </div>
  );
}

export default Home;
