import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Library from "./pages/Library";
import { useState } from "react";

import "./utils/style.scss";

function App() {
  const [isDisplayed, setDisplayed] = useState(() => {
    if (!sessionStorage.getItem("progression")) {
      return "0";
    } else {
      return sessionStorage.getItem("progression");
    }
  });

  useEffect(() => {
    sessionStorage.setItem("progression", isDisplayed);
  }, [isDisplayed]);

  return (
    <React.StrictMode>
      <Router>
        <div className="root2">
          <Header />
          <Routes>
            <Route
              path="/"
              element={
                <Home isDisplayed={isDisplayed} setDisplayed={setDisplayed} />
              }
            />
            <Route
              path="/library"
              element={<Library isDisplayed={isDisplayed} />}
            />
          </Routes>
        </div>
      </Router>
    </React.StrictMode>
  );
}

export default App;
