import LibraryCard from "./../../components/LibraryCard";

function Library({ isDisplayed }) {
  const riddlesData = require("./../../datas/riddles.json");
  const riddlesList = riddlesData.riddles;

  return (
    <div>
      <h1 className="intro">Revoir les anciens indices</h1>
      <div className="library_gallery">
        {riddlesList
          .slice(0, isDisplayed)
          .map(({ id, name, media, mediatype }) => (
            <div key={`div ${name}`} className="library_card_out">
              <LibraryCard media={media} mediatype={mediatype} />
            </div>
          ))}
      </div>
    </div>
  );
}

export default Library;
